var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","xl":"6","md":"8","offset-md":"2","offset-lg":"2","offset-xl":"3"}},[_c('h3',{staticClass:"mt-4 mb-3 text-center text-uppercase",class:{
                    'text-h5': _vm.$vuetify.breakpoint.smAndDown,
                    'text-h4': !_vm.$vuetify.breakpoint.smAndDown,
                }},[_vm._v(" Contact US "),_c('v-icon',{staticClass:"ml-2",attrs:{"x-large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.messageIcon)+" ")])],1),_c('v-form',{ref:"contactUsForm",attrs:{"lazy-validation":""},on:{"submit":_vm.onContactFormSubmit},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-card',[(_vm.contactMessageSent)?_c('v-card-text',{staticClass:"mt-4 mb-8 pb-0"},[_c('alert-box',{attrs:{"color":"success"}},[_vm._v(" We have received your message. we will get back to you as soon as possible ")])],1):_c('v-card-text',{staticClass:"mt-4 mb-0 pb-0"},[_c('alert-box',{staticClass:"mb-10 mt-n2",attrs:{"no-icon":true,"color":"info"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown
                                            ? '12'
                                            : '0'}},[_c('v-icon',{attrs:{"left":"","color":"info"}},[_vm._v(_vm._s(_vm.infoIcon))]),_vm._v(" Send us a message here and we will get back to you within 24 hours. You can also visit our frequently asked questions to see if your question has already been answered ")],1),_c('v-col',{staticClass:"shrink",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown
                                            ? '12'
                                            : '0'}},[_c('v-btn',{attrs:{"color":"secondary","to":{
                                            name: _vm.$constants.ROUTE_NAMES
                                                .FAQ_INDEX,
                                        },"block":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.helpIcon))]),_vm._v(" Visit FAQ ")],1)],1)],1)],1),_c('v-text-field',{staticClass:"mt-3",attrs:{"disabled":_vm.isSubmitting,"rules":_vm.formPhoneNumberRules,"error":_vm.formInputErrors.has('phone_number'),"error-messages":_vm.formInputErrors.get('phone_number'),"dark":_vm.$vuetify.theme.dark,"type":"number","hint":"Give us your mobile phone number so we can get in touch","label":"Phone number","placeholder":"Your mobile phone number.","outlined":""},model:{value:(_vm.formPhoneNumber),callback:function ($$v) {_vm.formPhoneNumber=$$v},expression:"formPhoneNumber"}}),_c('v-textarea',{staticClass:"mt-3",attrs:{"disabled":_vm.isSubmitting,"rules":_vm.formMessageRules,"error":_vm.formInputErrors.has('message'),"error-messages":_vm.formInputErrors.get('message'),"dark":_vm.$vuetify.theme.dark,"rows":"6","label":"Message","counter":500,"hint":"Max 500 characters","placeholder":"Write your message here.","outlined":""},model:{value:(_vm.formMessage),callback:function ($$v) {_vm.formMessage=$$v},expression:"formMessage"}}),_c('v-checkbox',{staticClass:"mt-4",attrs:{"disabled":_vm.isSubmitting,"dark":_vm.$vuetify.theme.dark},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" You can contact me on WhatApp "),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"#25D366"}},[_vm._v(_vm._s(_vm.whatsAppIcon))])]},proxy:true}]),model:{value:(_vm.formCanContactOnWhatsApp),callback:function ($$v) {_vm.formCanContactOnWhatsApp=$$v},expression:"formCanContactOnWhatsApp"}})],1),_c('v-card-actions',{staticClass:"px-4"},[(!_vm.contactMessageSent)?_c('v-btn',{attrs:{"type":"submit","large":"","block":_vm.$vuetify.breakpoint.mdAndDown,"color":_vm.formCanContactOnWhatsApp
                                    ? 'success'
                                    : 'primary',"disabled":_vm.isSubmitting}},[(_vm.isSubmitting)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":"25","color":"grey","indeterminate":""}}):_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.sendIcon))]),_vm._v(" Send Message ")],1):_vm._e(),(!_vm.contactMessageSent)?_c('v-spacer'):_vm._e(),(
                                !_vm.contactMessageSent &&
                                _vm.$vuetify.breakpoint.lgAndUp
                            )?_c('span',{staticClass:"text--secondary pr-2"},[_vm._v("We're social")]):_vm._e(),(
                                !_vm.contactMessageSent &&
                                _vm.$vuetify.breakpoint.lgAndUp
                            )?_c('social-buttons'):_vm._e(),(_vm.contactMessageSent)?_c('back-button',{attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown}}):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }