
import { Component, Vue } from "vue-property-decorator";
import ErrorService, { ValidationErrors } from "@/services/errors";
import AlertBox from "@/components/AlertBox.vue";
import {
    mdiEmail,
    mdiHelpCircle,
    mdiInformation,
    mdiSend,
    mdiWhatsapp,
} from "@mdi/js";
import BackButton from "@/components/BackButton.vue";
import ContactMessageApi, {
    StoreContactMessageInput,
} from "@/services/api/contact-message";
import { FormInputType, FormValidationRules, VForm } from "@/types/veutify";
import { AxiosError } from "axios";
import sentry from "@/plugins/sentry";
import { Getter } from "vuex-class";
import { User } from "@/models/user";
import { addAnalyticsEvent } from "@/plugins/firebase";
import SocialButtons from "@/components/SocialButtons.vue";
import { ApiResponse } from "@/services/api/axios";

function isValidPhoneNumber(value: string): boolean {
    return /([62])(2|3|[5-9])[0-9]{7}/.test(value);
}

@Component({
    components: { SocialButtons, BackButton, AlertBox },
})
export default class ContactUsIndex extends Vue {
    helpIcon: string = mdiHelpCircle;
    formPhoneNumber: string = "";
    infoIcon: string = mdiInformation;
    whatsAppIcon: string = mdiWhatsapp;
    formCanContactOnWhatsApp = true;
    formPhoneNumberRules: FormValidationRules = [
        (value: FormInputType) =>
            (typeof value === "string" &&
                value.length === 9 &&
                isValidPhoneNumber(value)) ||
            "Your phone number must be a Cameroonian phone number",
    ];
    formMessage: string = "";
    formMessageRules: FormValidationRules = [
        (value: FormInputType) =>
            (typeof value === "string" && value.length >= 9) ||
            "Your message must have more than 10 characters",
    ];
    isSubmitting: boolean = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    formIsValid: boolean | null = null;
    contactMessageSent = false;
    messageIcon: string = mdiEmail;

    @Getter("user") user!: User | null;

    get sendIcon(): string {
        if (this.formCanContactOnWhatsApp) {
            return mdiWhatsapp;
        }
        return mdiSend;
    }

    clearValidationErrors() {
        this.formInputErrors = new ValidationErrors();
    }

    onContactFormSubmit(event: Event) {
        event.preventDefault();
        this.formIsValid = (this.$refs["contactUsForm"] as VForm).validate();
        if (!this.formIsValid) {
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                "The form contains validation errors"
            );
            return;
        }

        if (this.formCanContactOnWhatsApp) {
            addAnalyticsEvent("contact_us_message_submitted_on_whatsapp");
            window.location.href =
                "https://wa.me/237673658376?text=" +
                encodeURI(this.formMessage);
            return;
        }

        addAnalyticsEvent("contact_us_message_submitted");
        this.clearValidationErrors();
        this.isSubmitting = true;
        this.$recaptcha("contact_us_message")
            .then((token) => {
                this.submitContactMessage({
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    user_id: this.user?.uid,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    can_contact_on_whatsapp: this.formCanContactOnWhatsApp,
                    message: this.formMessage,
                    captcha: token,
                });
            })
            .catch((error) => {
                this.handleError(error);
                this.isSubmitting = false;
            });
    }

    handleError(error: Error) {
        sentry.captureException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    submitContactMessage(input: StoreContactMessageInput) {
        ContactMessageApi.store(input)
            .then(() => {
                this.contactMessageSent = true;
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "We have received your message"
                );
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error.response?.data?.message ?? error.message)
        );
    }
}
